<template>
  <v-container class="about-section">
    <mq-layout mq="mobile">
      <mobile-menu>
        <v-col class="align-center">
          <v-row>
            <img
              src="@/assets/about-image.png"
              alt="mobile-image"
              class="mobile-image"
            >
          </v-row>
          <v-row>
            <p class="mobile-description">
              {{ $t("about.descr1") }}
            </p>
            <p class="mobile-description">
              {{ $t("about.descr2") }}
            </p>
            <p class="mobile-description">
              {{ $t("about.descr3") }}
            </p>
          </v-row>
        </v-col>
      </mobile-menu>
    </mq-layout>
    <mq-layout mq="tablet+">
      <desktop-menu>
        <v-row class="align-center">
          <v-col cols="5">
            <img
              src="@/assets/about-image.png"
              alt="about-image"
              class="about-image"
            >
          </v-col>
          <v-col cols="5">
            <p class="about-description">
              {{ $t("about.descr1") }}
            </p>
            <p class="about-description">
              {{ $t("about.descr2") }}
            </p>
            <p class="about-description">
              {{ $t("about.descr3") }}
            </p>
          </v-col>
        </v-row>
      </desktop-menu>
    </mq-layout>
  </v-container>
</template>

<script>
export default {
  name: "AboutView",

};
</script>

<style scoped lang="scss">

.about-description {
  font-size: 1.4vw;

}

.mobile-description { 
  font-size: auto;
}

.mobile-image { 
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

.about-image {
  width: auto;
  max-width: 100%;
}

.about-section {
  height: calc(100vh - 120px);
  padding-top: 90px;
}

</style>